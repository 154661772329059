import styled from 'styled-components';

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  background: #fff;
  padding: 0 24px;
  display: flex;
  width: 100%;
  background-color: ${props => props.bgColor};
`;

Container.Body = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;

  width: 373px;
  height: 46px;

  img {
    margin-right: 11px;
  }

  .text {
    font: 400 normal 1rem/1em 'Roboto', sans-serif;
    color: #171c24;
  }

  @media (max-width: 882px) {
    width: 100%;
    justify-content: center;

    img {
      width: 22px;
    }

    .text {
      font-size: 14px;
    }
  }
`;

export const Highlights = styled.span`
  font-weight: bolder;
`;
