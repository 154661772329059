import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdKeyboardBackspace } from 'react-icons/md';
import { rem } from 'polished';
import styled from 'styled-components';
import { get, find } from 'lodash';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { gtmEvents, flatten, checkToken } from '../../utils';
import * as AuctionsActions from '../../store/actions/auction.actions';
import * as eventTrackingActions from '../../store/actions/event-tracking.actions';
import { TagFincredit } from '../../components/TagFincredit';
import {
  Box,
  Flex,
  Text,
  Heading,
  ProgressTimer,
  Loading,
  Tabs,
  PhotoGallery,
  Layout,
  CarouselPhoto,
  Button,
  HorizontalLine,
} from '../../components';
import VidePlayer from '../../components/VidePlayer';
import { getTaxIdList, changeTaxIdForAuction } from '../../services/taxids';
import { Select } from '../../components/Select';
import { AuctionDetailsCTA } from '../../components/enhanced/app/AuctionDetailsCTA';
import AppConstants, { auctionStatus } from '../../constants/appConstants';
import { Summary, DocumentResearch, Section, DetailsTab, Appraiser } from './components';
import { AuctionBidders } from '../../components/enhanced';
import appConstants from '../../constants/appConstants';
import { ChangeTaxIdDialog } from '../../components/change-taxid-dialog';
import Modal from '../../components/enhanced/Modal';
import { Dialog } from '../../components/dialog';
import { TaxProfileIcon } from '../../components/tax-profile-icon';
import { TimingAuctionContextProvider } from '../../context/TimingAuctionContext';
import { toggleModal } from '../../store/actions/ui.actions';
import { GoAlert } from 'react-icons/go';
import {
  AUCTION_OFFER_KEY,
  AUCTION_BUY_KEY,
  AUCTION_AUTOMATIC_OFFER_KEY,
  TIEBREAKER_RULES_KEY,
  AUCTION_BUY_FOR,
} from '../../constants/modalConstants';
import { useTimer } from '../../hooks/useTimer';
import {
  VehicleInfoBanner,
  VehicleInformationsContainer,
  VehicleNameAndInfos,
  VehicleChronometerAndLastOffer,
  VehicleLastOfferLabel,
  VehicleLastOfferAndIsWinningStatus,
  VehicleLastOfferValue,
  VehicleIsWinningLabel,
  VehicleActions,
  Row,
  AutoBidViewNinja,
  AutoBidViewNinjaLabel,
  AlertIconImg,
  VehicleLastOfferResponsiveContainer,
  FeedbackAutoBidContainer,
  FeedbackAutoBidLabel,
  AutoBidRules,
  Content,
  Column,
  NewButton,
  SectionContent,
  Recommended,
  AvaliatorContent,
  TextEnding,
  TextEndingdiv,
  FavoriteDiv,
  VideoTitle,
  VideoContainer,
  PhotoCategoryButton,
  MainPhotoText,
  PhotoCategoryButtonContainer,
} from './styles';

import { useBid } from '../../hooks/useBid';
import AlertIcon from '../../images/icons/automaticOfferAlertIconDetailVehicle.svg';
import VolumeIcon from '../../images/icons/tabler_volume.svg';

import AutoBidAction from '../../components/BidActions/AutoBidAction';
import { useAutoBid } from '../../hooks/useAutoBid';
import { listAutoBidVehicles, listAllAutoBidVehicles } from '../../store/autobid/effects';
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import { createViews } from '../../store/createViews/effects.js';
import { removeViewStorage, viewedStorage } from '../../helpers/viewedStorage';
import { hotjar } from '../../utils/hotjar-script';
import { sendEvent } from '../../helpers/googleAnalytics';
import { useSegment } from '../../hooks/useSegment';
import { Temperature } from '../../components/Temperature';
import { formatPrice } from '../../utils/formatPrice.js';
import { PercentageTag } from '../../components/PercentageTag';
import { Tag } from '../../components/NewTag';
import MaximumQuality from './components/MaximumQuality';
import { NewAppraiser } from './components/new/Appraiser/index.jsx';
import { NewSection } from './components/new/Section/index.jsx';
import NewCarousel from '../../components/NewCarousel/index.jsx';
import { authGet } from '../../utils/fecth-wrappers.js';
import { checkFinancingEnabled, loadCreditAvaible } from '../../services/fincredit.js';

const trackGTM = (gtmEvent, auction, eTrackingActions) => {
  if (!auction.summary) return;
  eTrackingActions.trackAuctionEvent(gtmEvent, auction._id);
};

const photosCategory = [{ label: 'Fotos principais' }, { label: 'Fotos dos apontamentos' }];

const BackButton = styled(Button).attrs({
  variant: 'default',
  isRounded: true,
  fontSize: 1,
  height: { sm: rem(24), md: rem(34) },
  px: 2,
})`
  color: #ffffff !important;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: rgba(3, 3, 3, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
`;

const VIEW_STATUS = {
  IDLE: 0,
  CONFIRM_TAXID_CHANGE: 1,
  TAXID_CHANGE_SUCCESS: 2,
  TAXID_CHANGE_ERROR: 3,
};

const DetailsPage = ({
  history,
  auctionId,
  auctionsActions,
  eTrackingActions,
  selectAuction,
  auctionSelected,
  toggleModal,
  allAuctions,
  listAutoBidVehicles,
  listAllAutoBidVehicles,
  bidError,
  bidErrorMessage,
}) => {
  const [gallery, setGallery] = useState({
    show: false,
    slide: 0,
    images: [],
  });
  const [state, setState] = useState('loading');
  const [photoCategory, setPhotoCategory] = useState(0);
  const [status, setStatus] = useState({ name: VIEW_STATUS.IDLE, context: {} });
  const [changedTaxId, setChangedTaxId] = useState(null);
  const [mainGallery, setMainGallery] = useState({
    show: false,
    slide: 0,
  });
  const [size, setSize] = useState(window.screen.width);
  const [taxIdDialogChangeSuccess, setTaxIdDialogChangeSuccess] = useState(false);
  const [displaySimulationBanner, setDisplaySimulationBanner] = useState(false);
  const [fincreditInfo, setFincreditInfo] = useState();
  const [initialSummarryAmount, setInitialSummarryAmount] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState('');

  const taxIdsQuery = useQuery('taxids', getTaxIdList);
  const queryClient = useQueryClient();

  const { currentOffer, currentOfferLabel } = useBid(auctionSelected);
  const { page, trackButtonClicked, trackCarouselInteraction } = useSegment();

  const dispatch = useDispatch();

  const removeJpgLinks = photos =>
    photos?.filter(url => !url.endsWith('.jpg') && url.includes('vimeo')) || [];
  const videoUrl = removeJpgLinks(auctionSelected?.summary?.photos);
  const translaEmbedUrl = videoUrl.length > 0 ? videoUrl[0] : '';

  window.addEventListener('resize', () => setSize(window.screen.width));

  const {
    currentAutoBidConfigured,
    isActiveAutoBid,
    maximumAutoBidBudget,
    tieWithEdits,
    tieWithoutEdits,
    noEditsAndDesconfigured,
  } = useAutoBid(auctionSelected?._id);

  const { user } = useSelector(state => state.authentication);
  const { payload } = useSelector(state => state.listAllAutoBidVehicles);

  const updateContext = useUnleashContext();

  const remainingTime = useMemo(() => auctionSelected.auction?.remainingTime, [
    auctionSelected.auction,
  ]);
  const { secondsLeft: currentAuctionDuration } = useTimer(remainingTime);

  const { summary, auction: auctionData } = auctionSelected;

  const enabled = useFlag('auto-bid');
  const enabledPrices = useFlag('fipe-web-prices');
  const enabledMoreViewedTag = useFlag('more-viewed-tag');
  const enabledNewInspectionPoints = useFlag('inspection-points');
  const enabledRecommended = useFlag('similarVehicles');
  const enabledSimulationBanner = useFlag('banner-simulacao');
  const enableSendSimulation = useFlag('leilao_financiamento');
  const enableVideo = useFlag('inspection-video');

  let auctionTaxProfile = {};
  let newTaxId = {};

  const isWinning = useMemo(() => auctionSelected?.auction?.winning, [
    auctionSelected?.auction?.winning,
  ]);

  const loser = !currentAutoBidConfigured?.isWinner || !isWinning;

  const greaterOrTie =
    !currentAutoBidConfigured?.tie || currentOffer >= currentAutoBidConfigured?.balance;

  const vehicleHasAutoBidActive = useMemo(
    () => find(payload, { inspectionId: auctionSelected?._id, autoBidStatus: 'ON' }),
    [payload, auctionSelected?._id]
  );

  const vehicleWithoutUpdateLimit = useMemo(
    () =>
      currentAutoBidConfigured?.autoBidStatus === 'OFF' &&
      currentAutoBidConfigured?.updateLimit === 2,
    [currentAutoBidConfigured?.autoBidStatus, currentAutoBidConfigured?.updateLimit]
  );

  const losingInBothWays = useMemo(
    () => isActiveAutoBid && currentAutoBidConfigured?.updateLimit !== 2 && loser && greaterOrTie,
    [isActiveAutoBid, currentAutoBidConfigured, loser, greaterOrTie]
  );

  const losingInBothWaysWithoutEdits = useMemo(
    () => isActiveAutoBid && currentAutoBidConfigured?.updateLimit === 2 && loser && greaterOrTie,
    [isActiveAutoBid, currentAuctionDuration, loser, greaterOrTie]
  );

  const noEditsInBothWays = useMemo(
    () =>
      isActiveAutoBid &&
      currentAutoBidConfigured?.updateLimit === 2 &&
      !loser &&
      !currentAutoBidConfigured?.tie,
    [isActiveAutoBid, currentAutoBidConfigured, loser]
  );

  const automaticToSingleOfferLosing = useMemo(
    () => !enabled && vehicleHasAutoBidActive?.balance >= currentOffer,
    [enabled, vehicleHasAutoBidActive, currentOffer]
  );

  const handleSingleOfferModal = useCallback(() => {
    selectAuction(auctionSelected, { remainingTime: currentAuctionDuration * 1000 });
    toggleModal({
      key: AUCTION_OFFER_KEY,
      show: true,
      extras: { section: 'details' },
    });
    trackGTM(gtmEvents.CTA_MAKE_OFFER_DETAILS, auctionSelected._id, eTrackingActions);
    trackButtonClicked(auctionSelected._id, 'singleBid', auctionSelected?.auctionId, 'details');
  }, [selectAuction, auctionSelected, toggleModal, currentAuctionDuration, trackButtonClicked]);

  const handleAutomaticOfferModal = useCallback(() => {
    selectAuction(auctionSelected, { remainingTime: currentAuctionDuration * 1000 });
    toggleModal({
      key: AUCTION_AUTOMATIC_OFFER_KEY,
      show: true,
      extras: { selectedAuction: auctionSelected, section: 'details' },
    });
    trackButtonClicked(auctionSelected._id, 'autoBid', auctionSelected?.auctionId, 'details');
  }, [selectAuction, auctionSelected, toggleModal, currentAuctionDuration, trackButtonClicked]);

  const handleNegotiateExclusivityModal = useCallback(() => {
    selectAuction(auctionSelected, { remainingTime: currentAuctionDuration * 1000 });
    toggleModal({
      key: AUCTION_BUY_KEY,
      show: true,
      extras: {
        section: 'details',
        showFin: displaySimulationBanner,
        fincreditInfo: fincreditInfo,
        unleashFinFlag: enableSendSimulation,
      },
    });
    trackButtonClicked(
      auctionSelected._id,
      displaySimulationBanner && fincreditInfo ? 'exclusivityBidFinCredit' : 'exclusivityBid',
      auctionSelected?.auctionId,
      'details',
      new Date().getTime()
    );
  }, [selectAuction, auctionSelected, currentAuctionDuration, toggleModal, trackButtonClicked]);

  const handleBuyForModal = useCallback(() => {
    selectAuction(auctionSelected, { remainingTime: currentAuctionDuration * 1000 });
    toggleModal({
      key: AUCTION_BUY_FOR,
      show: true,
      extras: {
        section: 'details',
        showFin: displaySimulationBanner,
        fincreditInfo: fincreditInfo,
        unleashFinFlag: enableSendSimulation,
      },
    });
    trackButtonClicked(
      auctionSelected._id,
      displaySimulationBanner && fincreditInfo ? 'buyForBidFinCredit' : 'buyForBid',
      auctionSelected?.auctionId,
      'details',
      new Date().getTime()
    );
  }, [selectAuction, auctionSelected, currentAuctionDuration, toggleModal]);

  const handleTiebreakerRulesModal = useCallback(() => {
    toggleModal({
      key: TIEBREAKER_RULES_KEY,
      show: true,
    });
  }, [toggleModal]);

  useEffect(() => {
    page('Página de Detalhes');
  }, []);

  useEffect(
    function() {
      if (auctionId) {
        auctionsActions.setSelectedAuctionId(auctionId);
      } else {
        auctionsActions.setSelectedAuctionId(null);
      }
    },
    [auctionId]
  );

  useEffect(() => {
    listAutoBidVehicles(user.id);
  }, [user.id]);

  useEffect(() => {
    updateContext({ userId: user?.email });
  }, [user?.email]);

  useEffect(() => {
    if (user.isNinja() || !enabled) {
      listAllAutoBidVehicles();
    }
  }, [user.isNinja, enabled]);

  useEffect(() => {
    if (typeof window.hj !== 'undefined') {
      const userId = user.id || null;

      hotjar.identify(userId, user.email, user.dealershipName, user.isNinja());
    }
  }, [window.hj]);

  useEffect(() => {
    if (state !== 'loading' && state === 'default') {
      const storage = JSON.parse(localStorage.getItem(user?.email));

      if (!storage?.find(el => el.id === auctionSelected?.auctionId)) {
        dispatch(createViews(auctionSelected?._id, auctionSelected?.auctionId));
      }

      viewedStorage(user?.email, auctionSelected?.auctionId, auctionSelected?._id, new Date());
      removeViewStorage(user?.email);
    }
  }, [state, user]);

  const getTaxIdNameDiff = () => {
    const oldNickName = auctionTaxProfile;
    const newNickName = newTaxId;
    return [oldNickName, newNickName];
  };

  const changeTaxIdMutation = useMutation(changeTaxIdForAuction, {
    onSuccess: data => {
      const [oldTaxProfile, newTaxProfile] = getTaxIdNameDiff();
      setStatus({
        name: VIEW_STATUS.TAXID_CHANGE_SUCCESS,
        context: { oldTaxId: oldTaxProfile?.nickName, newTaxId: newTaxProfile?.nickName },
      });
      queryClient.invalidateQueries('taxids');
      setChangedTaxId(data);
    },
    onError: error => {
      console.error('Unexpected error ocurred', error);
      setStatus({
        ...status,
        name: VIEW_STATUS.TAXID_CHANGE_ERROR,
      });
    },
  });

  const openGallery = (index, images) => {
    setGallery({
      show: true,
      slide: index,
      images,
    });
  };

  const makeOfferHandler = () => {
    trackGTM(gtmEvents.CTA_MAKE_OFFER_DETAILS, auctionSelected, eTrackingActions);
  };

  const goBack = () => history.goBack();

  const { allAuctions: all } = useSelector(state => state.auctions);
  const v = find(all, { _id: auctionId });

  const [recommendeds, setRecommendeds] = useState([]);

  const fetchRecommendeds = async () => {
    const list = [];

    const {
      data: { result },
    } = await authGet(`${AppConstants.API.auctions}/auctions/list-recommended/${auctionId}`);

    result.forEach(({ _id }) => {
      const r = find(all, { _id });
      if (!r) return;
      list.push(r);
    });

    if (!list.length) return;

    list.slice(0, 10);
    list.sort((prev, next) => {
      const prevDate = prev.auction.startTime + prev.auction.initialDuration;
      const nextDate = next.auction.startTime + next.auction.initialDuration;

      return prevDate - nextDate;
    });

    setRecommendeds(list);
  };

  const useRecommendedsLayout = useCallback(() => {
    if (!recommendeds.length) return null;

    return (
      <Recommended.Content>
        <Recommended.Title>Você pode se interessar por</Recommended.Title>
        <NewCarousel auctions={recommendeds} isRecommendedByDetails />
      </Recommended.Content>
    );
  }, [recommendeds]);

  useEffect(() => {
    const possibleRender = enabledRecommended && !!all.length && auctionId;

    if (possibleRender) {
      fetchRecommendeds();
    }
  }, [enabledRecommended, all, auctionId]);

  async function initPage() {
    try {
      setState('loading');
      await checkToken().catch(() => dispatch(deauthenticate(() => history.push('/login'))));

      if (!allAuctions || allAuctions.length < 1) {
        await auctionsActions.fetchAllAuctions();
      }

      const _auction = await auctionsActions.fetchAuction(auctionId);
      setInitialSummarryAmount(
        _auction?.summary?.buyForPrice?.enabled
          ? _auction?.summary?.buyForPrice?.amount
          : _auction?.summary?.buynowPrice?.amount
      );
      if (!_auction) throw Error('Auction not found');
      selectAuction(_auction, { remaningTime: _auction.auction.remainingTime });
      sendEvent('auctionTracker', {
        dealershipId: user?.id,
        dealershipEmail: user?.email,
        dealershipName: user?.dealershipName,
        inspectionId: _auction?._id,
        eventCategory: 'Auctions',
        eventAction: 'Visualizar leilão',
        eventLabel: 'Leilão visualizado',
      });
      setState('default');
      trackGTM(gtmEvents.AUCTION_DETAILS_ACCESS, _auction, eTrackingActions);
    } catch (e) {
      setState('error');
      console.log(e);
    }
  }
  const fetchData = async () => {
    try {
      const response = await checkFinancingEnabled(user?.email);
      setDisplaySimulationBanner(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLoadData = async () => {
    try {
      const response = await loadCreditAvaible(user?.id, user?.email, initialSummarryAmount);
      setFincreditInfo(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateContext({ userId: user?.email });
  }, [user?.email]);

  useEffect(() => {
    if (enabledSimulationBanner) {
      fetchData();
    }
  }, [enabledSimulationBanner, initialSummarryAmount]);

  useEffect(() => {
    if (enabledSimulationBanner && initialSummarryAmount > 0) {
      fetchLoadData();
    }
  }, [enabledSimulationBanner, initialSummarryAmount]);

  useEffect(() => {
    initPage();
  }, [changedTaxId, auctionId]);

  if (state === 'loading') return <Loading centered />;

  if (state !== 'loading' && state !== 'default')
    return (
      <Box className="absolute-center text-center" width="80%">
        Houve um problema ao tentar carregar este detalhe
        <Button onClick={goBack} mx="auto" mt={2}>
          Voltar
        </Button>
      </Box>
    );

  const participationActive = auctionData.onGoing && auctionData.participating;
  const benchmarksFlat = flatten(summary?.evaluation?.benchmarks?.items?.map(el => el.items));
  const featuresFlat = flatten(summary?.evaluation?.features?.map(el => el.items));

  const handleTaxIdChange = ({ taxIdentificationNumber }) => {
    if (!taxIdentificationNumber) return;
    setStatus({ name: VIEW_STATUS.CONFIRM_TAXID_CHANGE, context: { taxIdentificationNumber } });
  };

  const handleTaxIdChangeConfirm = async () => {
    const newTaxIdNumber = status.context?.taxIdentificationNumber;
    setTaxIdDialogChangeSuccess(true);
    setStatus({ ...status, name: VIEW_STATUS.CONFIRM_TAXID_LOADING });
    await changeTaxIdMutation.mutateAsync({
      auctionId: auctionSelected._id,
      taxIdNumber: newTaxIdNumber,
    });
  };

  const handleTaxIdChangeCancel = () => {
    setStatus({ name: VIEW_STATUS.IDLE, context: {} });
  };

  const handleTaxIdChangeSuccessCTA = () => {
    setTaxIdDialogChangeSuccess(false);
    setStatus({ name: VIEW_STATUS < VIEW_STATUS.IDLE, context: {} });
  };

  const handleTaxIdChangeErrorCTA = () => {
    setStatus({ ...status, name: VIEW_STATUS.CONFIRM_TAXID_CHANGE });
  };

  const taxIdOptions = [];
  let selectedTaxId = null;
  let taxids = [];

  const getTaxProfileFromIdNumber = idNumber => {
    const [taxProfile] = taxids.filter(t => t.taxIdentificationNumber === idNumber);
    return taxProfile;
  };

  if (taxIdsQuery.isSuccess) {
    taxids = taxIdsQuery.data?.data?.data || [];
    const approvedTaxIds = taxids.filter(t => t.status === appConstants.TaxIdStatus.APPROVED);

    approvedTaxIds.forEach(
      ({ nickName: label, _id: value, taxIdentificationNumber, type, default: isDefault }) => {
        taxIdOptions.push({
          label,
          value,
          taxIdentificationNumber,
          default: isDefault,
          key: value,
          icon: <TaxProfileIcon profileType={type} />,
        });
      }
    );
  }

  if (auctionData.taxId) {
    [selectedTaxId] = taxIdOptions.filter(
      taxId => taxId?.taxIdentificationNumber === auctionData.taxId.taxIdentificationNumber
    );
    auctionTaxProfile = getTaxProfileFromIdNumber(auctionData.taxId.taxIdentificationNumber);
  } else if (taxIdOptions.length) {
    [selectedTaxId] = taxIdOptions.filter(taxId => taxId.default);
  }

  newTaxId = getTaxProfileFromIdNumber(status.context?.taxIdentificationNumber);

  return (
    <TimingAuctionContextProvider auction={auctionData}>
      <Layout header={false}>
        <Box width="100%" pb={3}>
          <BackButton onClick={goBack}>
            <Button.Icon>
              <MdKeyboardBackspace size={14} />
            </Button.Icon>{' '}
            Voltar
          </BackButton>
          <CarouselPhoto
            photos={summary.photos.filter(url => !url.includes('vimeo'))}
            bigArrows
            sm={1}
            md={2}
            lg={3}
            detailVehicle
            height={{ sm: 240, md: 361 }}
            onClickSlide={i => {
              setMainGallery({ show: true, slide: i });
            }}
            trackCarousel={() =>
              trackCarouselInteraction(auctionSelected?._id, auctionSelected?.auctionId, 'details')
            }
          >
            <Flex css={{ position: 'absolute', bottom: '20px', left: '10px' }}>
              {enabledMoreViewedTag && auctionData.numberOfDealersParticipating < 4 ? (
                <Tag.Container bg="#fff">
                  <Tag.Icon viewed color="#2274a5" />
                  <Tag.Text color="#2274a5">Mais visualizado</Tag.Text>
                </Tag.Container>
              ) : (
                <Tag.Container>
                  <Tag.Icon />
                  <Tag.Text>
                    <span style={{ fontWeight: 'bold' }}>Concorrido</span>:{' '}
                    {auctionData.numberOfDealersParticipating} participando
                  </Tag.Text>
                </Tag.Container>
              )}
              {auctionData?.favoriteCount >= 2 && (
                <FavoriteDiv>
                  <Tag.Container>
                    <Tag.Icon favorite color="#FF7B7B" />
                    <Tag.Text>Mais favoritado</Tag.Text>
                  </Tag.Container>
                </FavoriteDiv>
              )}
            </Flex>
          </CarouselPhoto>

          <VehicleInfoBanner>
            <VehicleInformationsContainer>
              <VehicleNameAndInfos>
                {summary?.make} {summary?.model} {summary?.version}
                {size >= 1025 && displaySimulationBanner && fincreditInfo && <TagFincredit />}
              </VehicleNameAndInfos>

              {size >= 1025 && !user.isNinja() && participationActive && (
                <Select
                  options={taxIdOptions}
                  selectedItem={selectedTaxId}
                  onChange={handleTaxIdChange}
                  variant="primary"
                  placeholder="Selecione um perfil"
                  listProps={{
                    style: {
                      width: '150%',
                    },
                  }}
                  hideTick
                />
              )}
            </VehicleInformationsContainer>

            <VehicleChronometerAndLastOffer>
              {size < 1025 && !user.isNinja() && participationActive && (
                <Select
                  options={taxIdOptions}
                  selectedItem={selectedTaxId}
                  onChange={handleTaxIdChange}
                  variant="primary"
                  placeholder="Selecione um perfil"
                  listProps={{
                    style: {
                      width: '150%',
                    },
                  }}
                  hideTick
                />
              )}

              <ProgressTimer
                auction={auctionSelected}
                labelProps={{
                  color: '#ffffff',
                  fontSize: 1,
                  width: 'auto',
                }}
                detailVehicle
                timeProps={{ color: '#ffffff', fontSize: 3 }}
                participationActive={participationActive}
              />

              {size >= 1025 && (
                <>
                  <VehicleLastOfferLabel>Lance atual do leilão:</VehicleLastOfferLabel>
                  <VehicleLastOfferAndIsWinningStatus
                    isParticipating={!user.isNinja() && participationActive}
                  >
                    <VehicleLastOfferValue>R$ {currentOfferLabel}</VehicleLastOfferValue>
                    {enabledPrices && (
                      <>
                        <PercentageTag
                          price={v?.summary?.marketPrice}
                          currentOffer={currentOffer}
                          isDetails
                        />
                        <PercentageTag
                          price={summary?.webPrice}
                          currentOffer={currentOffer}
                          web
                          isDetails
                        />
                      </>
                    )}
                  </VehicleLastOfferAndIsWinningStatus>
                  {!user.isNinja() && participationActive && (
                    <VehicleIsWinningLabel bg={auctionData?.winning ? '#05762d' : '#c50000'}>
                      {auctionData?.winning ? 'Ganhando' : 'Perdendo'}
                    </VehicleIsWinningLabel>
                  )}

                  {!user.isNinja() && auctionData?.winning && !isActiveAutoBid ? (
                    <Temperature.Basic
                      auction={v}
                      currentValue={auctionData?.winningBid?.amount}
                      isDetails
                    />
                  ) : null}

                  {tieWithEdits && enabled ? (
                    <FeedbackAutoBidContainer>
                      <GoAlert
                        size={40}
                        fill="#C50000"
                        style={{
                          alignSelf: 'flex-start',
                          marginRight: '.313rem',
                          height: '18px',
                        }}
                      />
                      <FeedbackAutoBidLabel>
                        <strong>
                          Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}. Insira
                          um limite maior para continuar na disputa.
                        </strong>{' '}
                        <AutoBidRules onClick={handleTiebreakerRulesModal}>
                          Consulte a regra de desempate.
                        </AutoBidRules>
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}

                  {tieWithoutEdits && enabled ? (
                    <FeedbackAutoBidContainer>
                      <GoAlert
                        size={40}
                        fill="#C50000"
                        style={{
                          alignSelf: 'flex-start',
                          marginRight: '.313rem',
                          height: '18px',
                        }}
                      />
                      <FeedbackAutoBidLabel>
                        <strong>
                          Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}.
                        </strong>{' '}
                        Edições encerradas, continue na disputa através da oferta avulsa
                        {auctionSelected?.summary.buynowPrice.enabled &&
                          ' ou negocie com exclusividade'}
                        .
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}

                  {losingInBothWays && !tieWithEdits && enabled ? (
                    <FeedbackAutoBidContainer>
                      <GoAlert
                        size={40}
                        fill="#C50000"
                        style={{
                          alignSelf: 'flex-start',
                          marginRight: '.313rem',
                          height: '18px',
                        }}
                      />
                      <FeedbackAutoBidLabel>
                        <strong>
                          O lance de {maximumAutoBidBudget} foi superado por outro lojista. Insira
                          um valor maior de limite para voltar a disputar o leilão.
                        </strong>
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}

                  {losingInBothWaysWithoutEdits && !tieWithoutEdits && enabled ? (
                    <FeedbackAutoBidContainer>
                      <GoAlert
                        size={40}
                        fill="#C50000"
                        style={{
                          alignSelf: 'flex-start',
                          marginRight: '.313rem',
                          height: '18px',
                        }}
                      />
                      <FeedbackAutoBidLabel>
                        <strong>
                          O lance de {maximumAutoBidBudget} foi superado por outro lojista.
                        </strong>{' '}
                        Edições encerradas, continue na disputa através da oferta avulsa
                        {auctionSelected?.summary.buynowPrice.enabled &&
                          ' ou negocie com exclusividade'}
                        .
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}

                  {noEditsInBothWays && enabled ? (
                    <FeedbackAutoBidContainer>
                      <AlertIconImg
                        src={AlertIcon}
                        alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                      />
                      <FeedbackAutoBidLabel>
                        Você não pode mais alterar o <strong>lance máximo</strong> porque atingiu o
                        número máximo de edições.
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}

                  {noEditsAndDesconfigured && enabled ? (
                    <FeedbackAutoBidContainer style={{ marginTop: '16px' }}>
                      <AlertIconImg
                        src={AlertIcon}
                        alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                      />
                      <FeedbackAutoBidLabel>
                        Você não pode mais fazer a <strong>oferta automática</strong> porque atingiu
                        o número máximo de edições.
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}

                  {automaticToSingleOfferLosing ? (
                    <FeedbackAutoBidContainer>
                      <GoAlert
                        size={110}
                        fill="#C50000"
                        style={{
                          alignSelf: 'flex-start',
                          marginRight: '.313rem',
                          height: '18px',
                        }}
                      />
                      <FeedbackAutoBidLabel>
                        <strong>
                          O seu lance foi superado rapidamente porque outro lojista definiu um valor
                          de oferta previamente que cobriu o seu lance de modo automático.
                        </strong>{' '}
                        Essa é uma nova funcionalidade, ainda está em teste e por isso não está
                        habilitada para você. Caso também queira utilizar esta funcionalidade, entre
                        em contato com o atendimento.
                      </FeedbackAutoBidLabel>
                    </FeedbackAutoBidContainer>
                  ) : null}
                </>
              )}
            </VehicleChronometerAndLastOffer>

            <VehicleLastOfferResponsiveContainer participating={participationActive}>
              {displaySimulationBanner && fincreditInfo && <TagFincredit />}
              <VehicleLastOfferLabel>Lance atual do leilão:</VehicleLastOfferLabel>
              <VehicleLastOfferAndIsWinningStatus
                isParticipating={!user.isNinja() && participationActive}
              >
                <VehicleLastOfferValue>R$ {currentOfferLabel}</VehicleLastOfferValue>
                {enabledPrices && (
                  <>
                    <PercentageTag
                      price={v?.summary?.marketPrice}
                      currentOffer={currentOffer}
                      isDetails
                    />
                    <PercentageTag
                      price={summary?.webPrice}
                      currentOffer={currentOffer}
                      web
                      isDetails
                    />
                  </>
                )}
              </VehicleLastOfferAndIsWinningStatus>
              {!user.isNinja() && participationActive && (
                <VehicleIsWinningLabel bg={auctionData?.winning ? '#438a4c' : '#fcab10'}>
                  {auctionData?.winning ? 'Ganhando' : 'Perdendo'}
                </VehicleIsWinningLabel>
              )}
              {!user.isNinja() && auctionData?.winning && !isActiveAutoBid ? (
                <>
                  <Temperature.Basic
                    auction={v}
                    currentValue={auctionData?.winningBid?.amount}
                    isDetails
                  />
                  <hr
                    style={{ width: '100%', margin: '18px 0 24px', backgroundColor: '#d6d6d6' }}
                  />
                </>
              ) : null}

              {tieWithEdits && enabled ? (
                <FeedbackAutoBidContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <FeedbackAutoBidLabel>
                    <strong>
                      Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}. Insira um
                      limite maior para continuar na disputa.
                    </strong>{' '}
                    <AutoBidRules onClick={handleTiebreakerRulesModal}>
                      Consulte a regra de desempate.
                    </AutoBidRules>
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}

              {tieWithoutEdits && enabled ? (
                <FeedbackAutoBidContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <FeedbackAutoBidLabel>
                    <strong>
                      Outro lojista definiu primeiro o limite de {maximumAutoBidBudget}.
                    </strong>{' '}
                    Edições encerradas, continue na disputa através da oferta avulsa
                    {auctionSelected?.summary.buynowPrice.enabled &&
                      ' ou negocie com exclusividade'}
                    .
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}

              {losingInBothWays && !tieWithEdits && enabled ? (
                <FeedbackAutoBidContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <FeedbackAutoBidLabel>
                    <strong>
                      O lance de {maximumAutoBidBudget} foi superado por outro lojista. Insira um
                      valor maior de limite para voltar a disputar o leilão.
                    </strong>
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}

              {losingInBothWaysWithoutEdits && !tieWithoutEdits && enabled ? (
                <FeedbackAutoBidContainer>
                  <GoAlert
                    size={40}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <FeedbackAutoBidLabel>
                    <strong>
                      O lance de {maximumAutoBidBudget} foi superado por outro lojista.
                    </strong>{' '}
                    Edições encerradas, continue na disputa através da oferta avulsa
                    {auctionSelected?.summary.buynowPrice.enabled &&
                      ' ou negocie com exclusividade'}
                    .
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}

              {automaticToSingleOfferLosing ? (
                <FeedbackAutoBidContainer>
                  <GoAlert
                    size={110}
                    fill="#C50000"
                    style={{
                      alignSelf: 'flex-start',
                      marginRight: '.313rem',
                      height: '18px',
                    }}
                  />
                  <FeedbackAutoBidLabel>
                    <strong>
                      O seu lance foi superado rapidamente porque outro lojista definiu um valor de
                      oferta previamente que cobriu o seu lance de modo automático.
                    </strong>{' '}
                    Essa é uma nova funcionalidade, ainda está em teste e por isso não está
                    habilitada para você. Caso também queira utilizar esta funcionalidade, entre em
                    contato com o atendimento.
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}

              {noEditsInBothWays && enabled ? (
                <FeedbackAutoBidContainer>
                  <AlertIconImg
                    src={AlertIcon}
                    alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                  />
                  <FeedbackAutoBidLabel>
                    Você não pode mais alterar o <strong>lance máximo</strong> porque atingiu o
                    número máximo de edições.
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}

              {noEditsAndDesconfigured && enabled ? (
                <FeedbackAutoBidContainer style={{ marginTop: '16px' }}>
                  <AlertIconImg
                    src={AlertIcon}
                    alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                  />
                  <FeedbackAutoBidLabel>
                    Você não pode mais fazer a <strong>oferta automática</strong> porque atingiu o
                    número máximo de edições.
                  </FeedbackAutoBidLabel>
                </FeedbackAutoBidContainer>
              ) : null}
            </VehicleLastOfferResponsiveContainer>

            <VehicleActions specificStyle={!summary.buynowPrice.enabled}>
              {user.isNinja() && vehicleHasAutoBidActive && enabled ? (
                <AutoBidViewNinja>
                  <AlertIconImg
                    src={AlertIcon}
                    alt="Círculo preenchido de cinza com um ponto de exclamação no centro."
                  />
                  <AutoBidViewNinjaLabel>
                    Não é possível dar lances pois o leilão está com a{' '}
                    <strong>oferta automática ativa.</strong>
                  </AutoBidViewNinjaLabel>
                </AutoBidViewNinja>
              ) : (
                <React.Fragment>
                  {isActiveAutoBid &&
                  !tieWithoutEdits &&
                  !losingInBothWaysWithoutEdits &&
                  enabled ? (
                    <AutoBidAction detailsVehicle auction={v} />
                  ) : (
                    <>
                      {user.isNinja() ||
                      vehicleWithoutUpdateLimit ||
                      currentAutoBidConfigured?.updateLimit === 2 ||
                      !enabled ? (
                        <Column>
                          <NewButton hover="#4ab971" onClick={handleSingleOfferModal}>
                            Oferta avulsa
                          </NewButton>

                          {!summary.buyForPrice.enabled && summary.buynowPrice.enabled && (
                            <view>
                              <TextEndingdiv>
                                <TextEnding>Negociar com exclusividade:</TextEnding>
                              </TextEndingdiv>
                              <NewButton
                                fontWeight={400}
                                bg="#ff4c00"
                                hover="#ff7941"
                                onClick={handleNegotiateExclusivityModal}
                              >
                                {displaySimulationBanner && fincreditInfo?.entryAmount ? (
                                  <>
                                    Negocie por R$ {formatPrice(summary?.buynowPrice?.amount)} à
                                    vista
                                    <br />
                                    <strong>
                                      {' '}
                                      ou entrada de R$ {formatPrice(fincreditInfo?.entryAmount)}
                                    </strong>
                                  </>
                                ) : (
                                  <>
                                    Negocie a partir de{' '}
                                    <strong>R$ {formatPrice(summary?.buynowPrice?.amount)}</strong>
                                  </>
                                )}
                              </NewButton>
                            </view>
                          )}

                          {summary.buyForPrice.enabled && (
                            <view>
                              <TextEndingdiv>
                                <TextEnding>Compra garantida:</TextEnding>
                              </TextEndingdiv>
                              <NewButton
                                onClick={handleBuyForModal}
                                fontWeight={400}
                                color="#000"
                                bg="#f2c94c"
                              >
                                {displaySimulationBanner && fincreditInfo?.entryAmount ? (
                                  <>
                                    Compre por R$ {formatPrice(summary.buyForPrice.amount)} à vista
                                    <br />
                                    <strong>
                                      {' '}
                                      ou entrada de R$ {formatPrice(fincreditInfo?.entryAmount)}
                                    </strong>
                                  </>
                                ) : (
                                  <>
                                    Compra garantida por{' '}
                                    <strong>R$ {formatPrice(summary.buyForPrice.amount)}</strong>
                                  </>
                                )}
                              </NewButton>
                            </view>
                          )}
                        </Column>
                      ) : (
                        <Column>
                          <Row>
                            <NewButton hover="#4ab971" onClick={handleSingleOfferModal}>
                              Oferta avulsa
                            </NewButton>
                            <NewButton hover="#4ab971" onClick={handleAutomaticOfferModal}>
                              Automática
                            </NewButton>
                          </Row>

                          {!summary.buyForPrice.enabled && summary.buynowPrice.enabled && (
                            <view>
                              <TextEndingdiv>
                                <TextEnding>Negociar com exclusividade:</TextEnding>
                              </TextEndingdiv>
                              <NewButton
                                fontWeight={400}
                                bg="#ff4c00"
                                hover="#ff7941"
                                onClick={handleNegotiateExclusivityModal}
                              >
                                {displaySimulationBanner && fincreditInfo?.entryAmount ? (
                                  <>
                                    Negocie por R$ {formatPrice(summary?.buynowPrice?.amount)} à
                                    vista
                                    <br />
                                    <strong>
                                      {' '}
                                      ou entrada de R$ {formatPrice(fincreditInfo?.entryAmount)}
                                    </strong>
                                  </>
                                ) : (
                                  <>
                                    Negocie a partir de{' '}
                                    <strong>R$ {formatPrice(summary?.buynowPrice?.amount)}</strong>
                                  </>
                                )}
                              </NewButton>
                            </view>
                          )}

                          {summary.buyForPrice.enabled && (
                            <view>
                              <TextEndingdiv>
                                <TextEnding>Compra garantida:</TextEnding>
                              </TextEndingdiv>
                              <NewButton
                                onClick={handleBuyForModal}
                                fontWeight={400}
                                color="#000"
                                bg="#f2c94c"
                              >
                                {displaySimulationBanner && fincreditInfo?.entryAmount ? (
                                  <>
                                    Compre por R$ {formatPrice(summary.buyForPrice.amount)} à vista
                                    <br />
                                    <strong>
                                      {' '}
                                      ou entrada de R$ {formatPrice(fincreditInfo?.entryAmount)}
                                    </strong>
                                  </>
                                ) : (
                                  <>
                                    Compra garantida por{' '}
                                    <strong>R$ {formatPrice(summary.buyForPrice.amount)}</strong>
                                  </>
                                )}
                              </NewButton>
                            </view>
                          )}
                        </Column>
                      )}
                    </>
                  )}
                </React.Fragment>
              )}
            </VehicleActions>
          </VehicleInfoBanner>

          <Content>
            <Summary
              auction={auctionData}
              summary={summary}
              marketPrice={v?.summary?.marketPrice}
            />

            {size > 1024 ? (
              <>
                <DocumentResearch showObservations research={summary} />
                <MaximumQuality />
              </>
            ) : null}

            <DocumentResearch showHistoric research={summary} />

            <div className="evaluation-section">
              {enabledNewInspectionPoints ? (
                <>
                  <SectionContent>
                    <NewSection.Container
                      title="Principais informações do veículo"
                      hideIcon
                      hideLine
                    >
                      <NewSection.Content items={summary?.evaluation?.highlights} />
                    </NewSection.Container>

                    {videoUrl.length !== 0 && enableVideo && (
                      <NewSection.Container hideIcon>
                        <VideoTitle>
                          <img src={VolumeIcon} style={{ marginRight: 5 }} />
                          Confira o som do motor
                        </VideoTitle>
                        <VideoContainer>
                          <VidePlayer video={translaEmbedUrl} />
                        </VideoContainer>
                      </NewSection.Container>
                    )}

                    <NewSection.Container title="Pontos de atenção">
                      {summary?.evaluation?.benchmarks?.rejected?.map(({ group, items }) => (
                        <NewSection.Content
                          key={group}
                          title={group}
                          items={items}
                          onGallery={() => {
                            setPhotoCategory(1);
                            setMainGallery({ show: true, slide: 0 });
                            setSelectedGroup(group);
                          }}
                          collapsed
                        />
                      ))}
                    </NewSection.Container>

                    <NewSection.Container title="Pontos aprovados" color="#22AA52">
                      {summary?.evaluation?.benchmarks?.accepted?.map(({ group, items }) => (
                        <NewSection.Content
                          key={group}
                          title={group}
                          items={items}
                          onGallery={() => {
                            setPhotoCategory(1);
                            setMainGallery({ show: true, slide: 0 });
                            setSelectedGroup(group);
                          }}
                          collapsed
                          auction={auctionData}
                        />
                      ))}
                    </NewSection.Container>

                    <NewSection.Container title="Opcionais" color="#056A8B" hideLine>
                      {summary?.evaluation?.features?.map(({ group, items }) => (
                        <NewSection.Content
                          key={group}
                          title={group}
                          items={items}
                          isOptional
                          onGallery={openGallery}
                          collapsed
                        />
                      ))}
                    </NewSection.Container>
                  </SectionContent>

                  <AvaliatorContent>
                    <NewAppraiser name={summary?.mechanicName} picture={summary?.mechanicPicture} />
                  </AvaliatorContent>
                </>
              ) : (
                <>
                  <Section
                    title="Resumo do Veículo"
                    counter={summary.evaluation.highlights.length}
                    items={summary.evaluation.highlights}
                    onOpenFullscreen={openGallery}
                    passed
                  />
                  <Tabs>
                    <DetailsTab />
                    <Tabs.TabPanel
                      tabId="rejected"
                      tabTitle="Pontos de atenção"
                      tabProps={{
                        color: 'alerts.Ferrari',
                        counter: benchmarksFlat?.filter(e => !e.passed).length,
                      }}
                    >
                      {summary.evaluation.benchmarks.rejected.map(({ group, items }) => (
                        <Section
                          title={group}
                          counter={items.length}
                          items={items}
                          onOpenFullscreen={openGallery}
                          key={group}
                        />
                      ))}
                    </Tabs.TabPanel>
                    <Tabs.TabPanel
                      tabId="accepted"
                      tabTitle="Pontos aprovados"
                      tabProps={{
                        color: 'secondaries.Uno',
                        counter: benchmarksFlat?.filter(e => e.passed).length,
                      }}
                      passed
                    >
                      {summary.evaluation.benchmarks.accepted.map(({ group, items }) => (
                        <Section
                          title={group}
                          counter={items.length}
                          items={items}
                          onOpenFullscreen={openGallery}
                          passed
                          key={group}
                        />
                      ))}
                    </Tabs.TabPanel>
                    <Tabs.TabPanel
                      tabId="features"
                      tabTitle="Opcionais do carro"
                      tabProps={{
                        color: 'secondaries.Uno',
                        counter: featuresFlat?.length,
                      }}
                      passed
                    >
                      {summary.evaluation.features.map(({ group, items }) => (
                        <Section
                          title={group}
                          counter={items.length}
                          items={items}
                          onOpenFullscreen={openGallery}
                          passed
                          key={group}
                        />
                      ))}
                    </Tabs.TabPanel>
                  </Tabs>

                  <Appraiser
                    appraiser={{
                      mechanicName: summary.mechanicName,
                      mechanicPicture: summary.mechanicPicture,
                    }}
                  />
                </>
              )}

              {useRecommendedsLayout()}

              {gallery.show && (
                <PhotoGallery
                  images={gallery.images}
                  slideIndex={gallery.slide}
                  onDismiss={() => {
                    setGallery({ ...gallery, show: false });
                  }}
                />
              )}

              {mainGallery.show && (
                <PhotoGallery
                  photos={summary.photos.filter(url => url.endsWith('.jpg'))}
                  categoryPhotos={summary?.evaluation?.benchmarks}
                  categorySelected={photoCategory}
                  nextCategoryGalery={true}
                  slideIndex={mainGallery.slide}
                  onDismiss={() => {
                    setMainGallery({ ...mainGallery, show: false });
                  }}
                  selectedGroup={selectedGroup}
                  Header={
                    <PhotoCategoryButtonContainer>
                      {photosCategory.map((photo, index) => (
                        <PhotoCategoryButton
                          isSelected={photoCategory === index}
                          key={index}
                          onClick={() => {
                            setPhotoCategory(index);
                            setMainGallery({ ...mainGallery, slide: 0 });
                          }}
                        >
                          <MainPhotoText isSelected={photoCategory === index}>
                            {photo.label}
                          </MainPhotoText>
                        </PhotoCategoryButton>
                      ))}
                    </PhotoCategoryButtonContainer>
                  }
                  Footer={
                    <ProgressTimer
                      auction={auctionSelected}
                      labelProps={{
                        color: '#ffffff',
                        fontSize: 0,
                        width: 'auto',
                      }}
                      timeProps={{ color: '#ffffff', fontSize: 2 }}
                      progressBarProps={{
                        style: {
                          borderRadius: 0,
                        },
                      }}
                      reverse
                    />
                  }
                  openCarousel={mainGallery.show}
                />
              )}
            </div>
          </Content>
        </Box>
        <Modal
          show={[
            VIEW_STATUS.TAXID_CHANGE_SUCCESS,
            VIEW_STATUS.TAXID_CHANGE_ERROR,
            VIEW_STATUS.CONFIRM_TAXID_CHANGE,
            VIEW_STATUS.CONFIRM_TAXID_LOADING,
          ].includes(status.name)}
          toggle={handleTaxIdChangeCancel}
          hideCloseBtn
        >
          {auctionTaxProfile && newTaxId && status.name === VIEW_STATUS.CONFIRM_TAXID_CHANGE && (
            <ChangeTaxIdDialog
              header="Você gostaria de alterar as informaçōes para emissão de documentos?"
              onCTAClick={handleTaxIdChangeConfirm}
              onSecondaryBtnClick={handleTaxIdChangeCancel}
              oldTaxId={auctionTaxProfile}
              newTaxId={newTaxId}
            />
          )}
          {taxIdDialogChangeSuccess && status.name === VIEW_STATUS.TAXID_CHANGE_SUCCESS && (
            <Dialog
              header="Alteração realizada com sucesso!"
              onCTAClick={handleTaxIdChangeSuccessCTA}
            >
              <p>
                Você acabou de atualizar as informações de emissão do DUT e da nota fiscal do{' '}
                {status.context?.oldTaxId} para {status.context?.newTaxId}
              </p>
            </Dialog>
          )}
          {status.name === VIEW_STATUS.TAXID_CHANGE_ERROR && (
            <Dialog
              header="Problema com a alteração"
              onCTAClick={handleTaxIdChangeErrorCTA}
              primaryBtnLabel="Tentar novamente"
              variant="error"
              primaryBtnVariant="error"
            >
              <p>
                Tivemos um problema com a tentativa de alteração de dados para emissão dos
                documentos. Por favor, tente novamente
              </p>
            </Dialog>
          )}
        </Modal>
      </Layout>
    </TimingAuctionContextProvider>
  );
};

DetailsPage.propTypes = {
  authentication: PropTypes.object.isRequired,
  auctionId: PropTypes.string.isRequired,
  auctionsActions: PropTypes.object.isRequired,
  eTrackingActions: PropTypes.object.isRequired,
  relatedDealers: PropTypes.array,
  user: PropTypes.object,
  history: PropTypes.object,
  bidError: PropTypes.bool,
  bidErrorMessage: PropTypes.string,
  selectAuction: PropTypes.func,
  auctionSelected: PropTypes.object,
};

const mapStateToProps = (
  {
    authentication,
    user: { relatedDealers },
    authentication: { user },
    auctions: { bidErrorMessage, bidError, detailedAuction, allAuctions },
  },
  { match }
) => ({
  authentication,
  auctionId: get(match, 'params.id'),
  relatedDealers,
  user,
  bidErrorMessage,
  bidError,
  auctionSelected: detailedAuction,
  allAuctions,
});

const mapDispatchToProps = dispatch => ({
  auctionsActions: bindActionCreators(AuctionsActions, dispatch),
  eTrackingActions: bindActionCreators(eventTrackingActions, dispatch),
  selectAuction: bindActionCreators(AuctionsActions.setSelectedAuction, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch),
  listAutoBidVehicles: bindActionCreators(listAutoBidVehicles, dispatch),
  listAllAutoBidVehicles: bindActionCreators(listAllAutoBidVehicles, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsPage));
