import styled from 'styled-components';

const Container = styled.div`
  .see-photos {
    display: block;
    margin: 10px 0;
    font: 500 normal 1rem/1em 'Rubik', sans-serif;
    color: #2274a5;
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin: 20px 0 18px;
  cursor: pointer;

  .group-title {
    font: 700 normal 20px/16px 'Nunito', sans-serif;
    color: #2f3741;
  }

  .dropdown-list-title {
    font: 700 normal 16px/16px 'Nunito', sans-serif;
    color: #2f3741;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 97%;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;

  .card-image {
    max-height: 100%;
    height: 164px;
    cursor: pointer;
  }

  .card-description {
    padding: 8px;
    font: 700 normal 12px/16px 'Roboto', sans-serif;
    color: #2f3741;
  }

  .resize-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 20px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
    border-top-right-radius: 6px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 97%;
    width: 100%;
  }
`;

const WithoutImage = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px 80px;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }

  .onlyText {
    padding: 12px 8px 12px 0;
    font: 400 normal 14px/20px 'Roboto', sans-serif;
    color: #000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 95%;
  }
`;

export const Image = styled.img`
  width: 28px;
  height: 28px;
`;

export const Row = styled.div`
  display: flex;
  column-gap: 6px;
`;

export const Tooltip = styled.span`
  position: relative;
  display: flex;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 16px 14px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 4px;
  font: 700 normal 0.875rem/1em 'Rubik', sans-serif;
  color: ${({ color }) => color};
  @media screen and (min-width: 1024px) {
    display: inline-block;
    max-width: 448px;
    width: auto;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 20px;
    width: 0;
    height: 0;
    margin-left: calc(6px * -1);
    border: 6px solid transparent;
    border-bottom-color: ${({ bgColor }) => bgColor};
    pointer-events: none;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #eaebec;
  margin: 40px 0 22px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const S = {
  Container,
  Title,
  CarouselContainer,
  Card,
  WithoutImage,
  Divider,
};
