import React from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { Container, ImgWrapper, Image, Message, Messages, CustomRadixContent } from './styles';

import BadgeCautelar from '../../images/icons/badge_cautelar_aprovada.svg';
import BadgeLongarina from '../../images/icons/badge_longarina.svg';
import BadgeColunas from '../../images/icons/badge_colunas.svg';
import BadgeBomKm from '../../images/icons/badge_bom_km.svg';
import BadgeCambio from '../../images/icons/badge_cambio.svg';
import BadgeScanner from '../../images/icons/badge_scanner.svg';
import BadgeMargem from '../../images/icons/badge_margem.svg';
import BadgeRevenda from '../../images/icons/badge_revenda.svg';
import BadgeUberRJ from '../../images/icons/badge_uber_rj.svg';
import BadgeUberSP from '../../images/icons/badge_uber_sp.svg';
import BadgeUberBoothStates from '../../images/icons/badge_uber.svg';
import PlusBadges from '../../images/icons/plus_badges.svg';

function Tooltip({ message, children }) {
  return (
    <RadixTooltip.Root>
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

      <CustomRadixContent side="top" align="center">
        {message}
        <RadixTooltip.Arrow />
      </CustomRadixContent>
    </RadixTooltip.Root>
  );
}

const EvaluationBadges = ({ auction }) => {
  const flagBadgeCautelar = useFlag('badge-cautelar');
  const flagBadgeLongarina = useFlag('badge-longarina');
  const flagBadgeColunas = useFlag('badge-colunas');
  const flagBadgeKm = useFlag('badge-km');
  const flagBadgeCambio = useFlag('badge-cambio');
  const flagBadgeScanner = useFlag('badge-scanner');
  const flagBadgeMargem = useFlag('badge-margem');
  const flagBadgeRevenda = useFlag('badge-giro');
  const flagBadgeUberSP = useFlag('badge-uber-sp');
  const flagBadgeUberRJ = useFlag('badge-uber-rj');
  const flagBadgeUberBoothStates = useFlag('badge-uber');

  const ICONS = {
    BadgeCautelar,
    BadgeLongarina,
    BadgeColunas,
    BadgeBomKm,
    BadgeCambio,
    BadgeScanner,
    BadgeMargem,
    BadgeRevenda,
    BadgeUberSP,
    BadgeUberRJ,
    BadgeUberBoothStates,
    PlusBadges,
  };

  const shouldShowUberBoothStates =
    auction?.summary?.canHaveUberSPBadge &&
    auction?.summary?.canHaveUberRJBadge &&
    flagBadgeUberBoothStates;

  const shouldShowUberSP =
    auction?.summary?.canHaveUberSPBadge && flagBadgeUberSP && !shouldShowUberBoothStates;

  const shouldShowUberRJ =
    auction?.summary?.canHaveUberRJBadge && flagBadgeUberRJ && !shouldShowUberBoothStates;

  const shouldShowCautelar = auction?.summary?.precautionaryReport && flagBadgeCautelar;

  const shouldShowLongarina =
    auction?.summary?.canHaveCarSparBadge && flagBadgeLongarina && !shouldShowCautelar;

  const shouldShowColunas =
    auction?.summary?.canHaveColumnBadge && flagBadgeColunas && !shouldShowCautelar;

  const MESSAGES = isHidden => ({
    BadgeCautelar: (
      <Message isHidden={isHidden}>
        <strong>Cautelar sem apontamentos</strong> Cautelar verificada e aprovada
      </Message>
    ),
    BadgeLongarina: (
      <Message isHidden={isHidden}>
        <strong>Longarina sem apontamentos</strong> Verificada e sem observações
      </Message>
    ),
    BadgeColunas: (
      <Message isHidden={isHidden}>
        <strong>Colunas sem apontamentos</strong> Verificadas e sem observações
      </Message>
    ),
    BadgeBomKm: (
      <Message isHidden={isHidden}>
        <strong>Boa km por ano </strong> Na média de km para carros <br /> do mesmo ano
      </Message>
    ),
    BadgeCambio: (
      <Message isHidden={isHidden}>
        <strong>Câmbio sem apontamentos</strong> Teste inerte sem ressalvas
      </Message>
    ),
    BadgeScanner: (
      <Message isHidden={isHidden}>
        <strong>Scanner sem apontamentos</strong> Verificado e sem observações
      </Message>
    ),
    BadgeMargem: (
      <Message isHidden={isHidden}>
        <strong>Margem alta na revenda</strong> 70% abaixo do preço da web
      </Message>
    ),
    BadgeRevenda: (
      <Message isHidden={isHidden}>
        <strong>Giro rápido</strong> Mais vendidos segundo a Fenabrave
      </Message>
    ),
    BadgeUberSP: (
      <Message isHidden={isHidden}>
        <strong>Aceito no Uber Black SP e outros</strong> Modelo autorizado para Uber Black <br />
        em São Paulo, Brasília, Curitiba, Belo Horizonte e Porto Alegre
      </Message>
    ),
    BadgeUberRJ: (
      <Message isHidden={isHidden}>
        <strong>Aceito no Uber Black RJ e outros</strong> Modelo autorizado para Uber Black <br />
        no Rio de Janeiro, Campinas, Salvador, Recife, <br /> Florianópolis, Goiânia, Fortaleza e
        Vitória
      </Message>
    ),
    BadgeUberBoothStates: (
      <Message isHidden={isHidden}>
        <strong>Aceito no Uber Black SP, RJ e outros</strong> Modelo autorizado para Uber Black{' '}
        <br /> em São Paulo, Brasília, Curitiba, Belo Horizonte, <br />
        Porto Alegre, Rio de Janeiro, Salvador, Recife, Florianópolis, <br />
        Goiânia, Fortaleza e Vitória
      </Message>
    ),
  });

  const ICONS_STATUS = {
    BadgeCautelar: shouldShowCautelar,
    BadgeLongarina: shouldShowLongarina,
    BadgeColunas: shouldShowColunas,
    BadgeBomKm: auction?.summary?.kilometrageAcceptable && flagBadgeKm,
    BadgeCambio: auction?.summary?.canHaveTransmissionBadge && flagBadgeCambio,
    BadgeScanner: auction?.summary?.canHaveScannerBadge && flagBadgeScanner,
    BadgeMargem: auction?.summary?.isBelowThreshold && flagBadgeMargem,
    BadgeRevenda: auction?.summary?.fastSpin && flagBadgeRevenda,
    BadgeUberSP: shouldShowUberSP,
    BadgeUberRJ: shouldShowUberRJ,
    BadgeUberBoothStates: shouldShowUberBoothStates,
  };

  const getTrueIcons = () => {
    const trueIcons = Object.keys(ICONS_STATUS).filter(key => ICONS_STATUS[key]);

    if (trueIcons.length > 3) {
      const visibleIcons = trueIcons.slice(0, 3);
      const hiddenIcons = trueIcons.slice(3);

      return {
        ...visibleIcons.reduce((result, key) => {
          result[key] = {
            icon: ICONS[key],
            message: MESSAGES(false)[key],
          };
          return result;
        }, {}),
        Plus: {
          icon: ICONS.PlusBadges,
          message: (
            <Messages>
              {hiddenIcons.map((key, index) => (
                <div key={index}>{MESSAGES(true)[key]}</div>
              ))}
            </Messages>
          ),
        },
      };
    }

    return trueIcons.reduce((result, key) => {
      result[key] = {
        icon: ICONS[key],
        message: MESSAGES(false)[key],
      };
      return result;
    }, {});
  };

  const iconsToDisplay = getTrueIcons();

  return (
    <Container>
      {Object.entries(iconsToDisplay).map(([key, { icon, message }]) => (
        <Tooltip key={key} message={message}>
          <ImgWrapper>
            <Image src={icon} alt={`Badge ${key}`} />
          </ImgWrapper>
        </Tooltip>
      ))}
    </Container>
  );
};

export default EvaluationBadges;
