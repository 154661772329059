import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { Image, Row, S, Tooltip } from './styles';
import { FlagIcon } from '../../../../../components/Icons';
import { useFlag } from '@unleash/proxy-client-react';

import Cautelar from '../../../../../images/icons/cautelar.svg';
import Km from '../../../../../images/icons/km.svg';
import Longarina from '../../../../../images/icons/badge_longarina.svg';
import Colunas from '../../../../../images/icons/badge_colunas.svg';
import Cambio from '../../../../../images/icons/badge_cambio.svg';
import Scanner from '../../../../../images/icons/badge_scanner.svg';

function sortbyImages(items) {
  const photos = [];
  const texts = [];
  items.map(el => (el.img && el.img.src ? photos.push(el) : texts.push(el)));
  return { photos: photos, texts: texts };
}

export const Content = ({
  title,
  items,
  auction,
  onGallery,
  isOptional = false,
  collapsed = false,
}) => {
  const sortedItems = sortbyImages(items);
  const [opened, setOpened] = useState(collapsed);

  const flagBadgeCautelar = useFlag('badge-cautelar');
  const flagBadgeLongarina = useFlag('badge-longarina');
  const flagBadgeColunas = useFlag('badge-colunas');
  const flagBadgeKm = useFlag('badge-km');
  const flagBadgeCambio = useFlag('badge-cambio');
  const flagBadgeScanner = useFlag('badge-scanner');

  const shouldShowCautelar = auction?.vehicle?.precautionaryReport && flagBadgeCautelar;
  const shouldShowLongarina =
    auction?.vehicle?.canHaveCarSparBadge && flagBadgeLongarina && !shouldShowCautelar;
  const shouldShowColunas =
    auction?.vehicle?.canHaveColumnBadge && flagBadgeColunas && !shouldShowCautelar;
  const shouldShowBomKm = auction?.vehicle?.kilometrageAcceptable && flagBadgeKm;
  const shouldShowCambio = auction?.vehicle?.canHaveTransmissionBadge && flagBadgeCambio;
  const shouldShowScanner = auction?.vehicle?.canHaveScannerBadge && flagBadgeScanner;

  const BADGE_TYPES = {
    Estrutura: ['BadgeCautelar', 'BadgeLongarina', 'BadgeColunas', 'BadgeBomKm'],
    Mecânica: ['BadgeCambio', 'BadgeScanner'],
  };

  const BADGE_STATUS = {
    BadgeCautelar: shouldShowCautelar,
    BadgeLongarina: shouldShowLongarina,
    BadgeColunas: shouldShowColunas,
    BadgeBomKm: shouldShowBomKm,
    BadgeCambio: shouldShowCambio,
    BadgeScanner: shouldShowScanner,
  };

  const BADGE_DESCRIPTIONS_TYPES = {
    Estrutura: {
      badges: [
        'BadgeCautelar',
        'BadgeCautelarBomKm',
        'BadgeLongarina',
        'BadgeLongarinaBomKm',
        'BadgeColunas',
        'BadgeColunasBomKm',
        'BadgeBomKm',
        'BadgeLongarinaColunas',
        'AllStructureBadges',
      ],
      color: '#FFE8D8',
      bgColor: '#FF6C1C',
    },
    Mecânica: {
      badges: ['BadgeCambio', 'BadgeScanner', 'AllMechanicBadges'],
      color: '#BCF738',
      bgColor: '#007E62',
    },
  };

  const BADGE_DESCRIPTIONS_STATUS = {
    BadgeCautelar: shouldShowCautelar && !shouldShowBomKm,
    BadgeCautelarBomKm: shouldShowCautelar && shouldShowBomKm,
    BadgeLongarina: shouldShowLongarina && !shouldShowBomKm && !shouldShowColunas,
    BadgeLongarinaBomKm: shouldShowLongarina && shouldShowBomKm && !shouldShowColunas,
    BadgeColunas: shouldShowColunas && !shouldShowBomKm && !shouldShowLongarina,
    BadgeBomKm:
      shouldShowBomKm && !shouldShowCautelar && !shouldShowColunas && !shouldShowLongarina,
    BadgeColunasBomKm: shouldShowColunas && shouldShowBomKm && !shouldShowLongarina,
    BadgeLongarinaColunas: shouldShowLongarina && shouldShowColunas && !shouldShowBomKm,
    AllStructureBadges: shouldShowLongarina && shouldShowColunas && shouldShowBomKm,
    BadgeCambio: shouldShowCambio && !shouldShowScanner,
    BadgeScanner: shouldShowScanner && !shouldShowCambio,
    AllMechanicBadges: shouldShowCambio && shouldShowScanner,
  };

  const BADGE_DESCRIPTIONS = {
    BadgeCautelar: 'Cautelar sem apontamentos.',
    BadgeCautelarBomKm: 'Cautelar sem apontamentos, boa km por ano.',
    BadgeLongarina: 'Longarina sem apontamentos.',
    BadgeLongarinaBomKm: 'Longarina sem apontamentos, boa km por ano.',
    BadgeBomKm: 'Boa km por ano.',
    BadgeColunas: 'Colunas sem apontamentos.',
    BadgeColunasBomKm: 'Colunas sem apontamentos, boa km por ano.',
    BadgeLongarinaColunas: 'Longarina e colunas sem apontamentos.',
    AllStructureBadges: 'Longarina e colunas sem apontamentos, boa km por ano.',
    BadgeCambio: 'Câmbio sem apontamentos.',
    BadgeScanner: 'Scanner sem apontamentos.',
    AllMechanicBadges: 'Câmbio e scanner sem apontamentos.',
  };

  const BADGES = [
    {
      icone: Cautelar,
      titulo: 'Cautelar sem apontamentos',
      descricao: 'Cautelar verificada e aprovada',
    },
    {
      icone: Longarina,
      titulo: 'Longarina sem apontamentos',
      descricao: 'Verificada e sem observações',
    },
    {
      icone: Colunas,
      titulo: 'Colunas sem apontamentos',
      descricao: 'Verificadas e sem observações',
    },
    {
      icone: Km,
      titulo: 'Boa km por ano',
      descricao: 'Na média de km para carros do mesmo ano',
    },
    {
      icone: Cambio,
      titulo: 'Câmbio sem apontamentos',
      descricao: 'Teste inerte sem ressalvas',
    },
    {
      icone: Scanner,
      titulo: 'Scanner sem apontamentos',
      descricao: 'Verificado e sem observações',
    },
  ];

  const badgeKeyForStatus = Object.keys(BADGE_STATUS).filter(key => BADGE_STATUS[key]);

  const getTrueBadgesByType = type => {
    const badgeKeyForType = BADGE_TYPES[type] || [];
    const trueBadgesKeys = badgeKeyForStatus.filter(key => badgeKeyForType.includes(key));

    return trueBadgesKeys.reduce((result, key) => {
      const badgeIndex = Object.keys(BADGE_STATUS).indexOf(key);
      if (badgeIndex !== -1 && BADGES[badgeIndex]) {
        result[key] = BADGES[badgeIndex];
      }
      return result;
    }, {});
  };

  const descriptionKeyForStatus = Object.keys(BADGE_DESCRIPTIONS_STATUS).filter(
    key => BADGE_DESCRIPTIONS_STATUS[key]
  );

  const getTrueDescription = type => {
    const descriptionKeyForType = BADGE_DESCRIPTIONS_TYPES[type];

    if (!descriptionKeyForType) {
      console.warn(`Tipo inválido: ${type}`);
      return [];
    }

    const { badges, color, bgColor } = descriptionKeyForType;

    const trueDescriptionKeys = descriptionKeyForStatus.filter(key => badges.includes(key));

    return trueDescriptionKeys.map(key => ({
      description: BADGE_DESCRIPTIONS[key],
      color,
      bgColor,
    }));
  };

  const badgesToDisplay = getTrueBadgesByType(title);
  const descriptionToDisplay = getTrueDescription(title);

  if (!items.length) return null;

  const useTitleLayout = () => {
    if (!title) return null;

    return (
      <S.Title onClick={() => setOpened(!opened)}>
        <span className="dropdown-list-title">
          {title.includes('Mecanica') ? 'Mecânica' : title}
        </span>

        <Row>
          {Object.entries(badgesToDisplay).map(([key, badge]) => (
            <Image src={badge.icone} alt={`Badge ${key}`} />
          ))}
        </Row>

        {opened ? <MdKeyboardArrowDown size={27} /> : <MdKeyboardArrowUp size={27} />}
      </S.Title>
    );
  };

  return (
    <React.Fragment>
      {useTitleLayout()}

      {opened && !!sortedItems?.photos?.length ? (
        <React.Fragment>
          {descriptionToDisplay.map(({ description, color, bgColor }) => (
            <Tooltip color={color} bgColor={bgColor}>
              {description}
            </Tooltip>
          ))}

          <u className="see-photos" onClick={onGallery}>
            Ver Fotos
          </u>

          <S.WithoutImage isOptional={isOptional}>
            {sortedItems?.photos?.map(({ description }) => (
              <span className="onlyText" key={description}>
                {description}
              </span>
            ))}
          </S.WithoutImage>
        </React.Fragment>
      ) : null}

      {opened && !!sortedItems?.texts?.length ? (
        <S.WithoutImage isOptional={isOptional}>
          {sortedItems?.texts?.map(({ description }) => (
            <span className="onlyText" key={description}>
              {description}
            </span>
          ))}
        </S.WithoutImage>
      ) : null}
    </React.Fragment>
  );
};

export const Container = ({ title, hideIcon = false, color, children, hideLine = false }) => {
  return (
    <S.Container>
      <S.Title>
        {!hideIcon ? <FlagIcon color={color} /> : null}
        <span className="group-title">{title}</span>
      </S.Title>
      {children}
      {!hideLine ? <S.Divider /> : null}
    </S.Container>
  );
};

export const NewSection = {
  Container,
  Content,
};
